
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ScreenDetailPage extends Vue {
  private paramsList = []
  private info = {
  }

  get screenId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getParamsList()
    this.getDetail()
  }

  getParamsList () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'bigScreen'
    }).then(res => {
      this.paramsList = res.bigScreen || []
    })
  }

  // 大屏详情
  getDetail () {
    this.$axios.get(this.$apis.screen.selectCustomerScreenById, {
      id: this.screenId
    }).then((res) => {
      this.info = res
    })
  }
}
